<template>
  <div id="print"><p style="text-align: center; font-weight: 600">开票通知单</p>
    <p style="float: left; width: 500px; font-weight: 200">客户名称：{{ currentData.customer }}
    </p>
    <div>
      <table>
        <tbody>
          <tr>
            <th>费用名称</th>
            <th>房屋租金</th>
            <th>水费</th>
            <th>电费</th>
            <th>其他费用</th>
            <th>合计</th>
          </tr>
          <tr>
            <td>金额</td>
            <td>{{ moneyFormatter(rentFee) }}</td>
            <td>{{ moneyFormatter(waterFee) }}</td>
            <td>{{ moneyFormatter(electricFee) }}</td>
            <td>{{ moneyFormatter(otherFee) }}</td>
            <td>{{ moneyFormatter(currentData.sum_amount) }}</td>
          </tr>
          <tr>
            <td>备注</td>
            <td colspan="5">{{ currentData.submit_remark || currentData.apply_remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p id="footer"><span>开票人：{{ userInfo.name }}</span><span>申请人：{{ currentData.applicant_name }}</span><span>部门经理：陈瑞雯</span><span>申请时间：{{ date }}</span></p></div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'MmsInvoiceNoticeBill',
  components: {},
  props: {
    currentData: {
      type: Object,
      default: null
    },
    balanceData: {
      type: Array,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date(),
      rentFee: 0,
      waterFee: 0,
      electricFee: 0,
      otherFee: 0
    }
  },
  methods: {
    initData() {
      const balanceData = this.balanceData
      if (balanceData) {
        let rentFee = 0; // 租金
        let waterFee = 0; // 水费
        let electricFee = 0; // 电费
        let otherFee = 0; // 其他费用
        balanceData.forEach(fee => {
            if (fee.fee_name === '租金') {
              rentFee += fee.charge;
            } else if (fee.fee_name === '水费') {
              waterFee += fee.charge;
            } else if (fee.fee_name === '电费') {
              electricFee += fee.charge;
            } else {
              otherFee += fee.charge;
            }
        });
        this.rentFee = rentFee
        this.waterFee = waterFee
        this.electricFee = electricFee
        this.otherFee = otherFee
      }
      this.orderData = this.currentData
      this.orderList = this.balanceData
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 90%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse; text-align: center; height: 300px }
table td, th { border: 1px solid #000 }
#footer span { display: inline-block; width: 20% }
</style>
