<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票单号">
              <a-input v-model="queryParam.serial_num" placeholder="根据开票单号搜索" allowClear/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.order_num" placeholder="根据订单编号搜索" allowClear/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票状态">
              <a-select
                allowClear
                placeholder="请选择开票状态"
                style="width: 100%"
                v-model="queryParam.audit_status"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="接单日期">
              <a-range-picker style="width: 100%" show-time @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="printInvoiceSheet(record, index)">查看</a>
        </template>
      </span>
      <div slot="orderNumSet" slot-scope="text">
        <span v-for="item in text" :key="item" style="display:block">{{ item }}</span>
      </div>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '' }}
        </a-tag>
      </div>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.fees" :key="item.id">
          <span v-if="item.pay_target_name">{{ item.pay_target_name }}{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
          <span v-else>{{ item.fee_name }}:{{ item.charge }}</span>
        </div>
      </div>
      <div slot="invoiceItem" slot-scope="text, record">
        <div v-for="item in record.invoices" :key="item.id">
          <span v-if="item.invoice_type_code==='026'||item.invoice_type_code==='028'||item.invoice_type_code==='01'||item.invoice_type_code==='02'">
            发票号：
            <a v-if="item.void_status === 0 && item.is_download === '1'" style="color: red" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
            <span v-if="item.void_status === 1">{{ item.invoice_no }}<a-tag color="red">失效</a-tag></span>
            <a v-if="item.void_status === 0 && item.is_download === '0'" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
          </span>
          <span v-else>发票号：{{ item.invoice_no }}</span>
        </div>
      </div>
      <div slot="fmsLogsItem" slot-scope="text, record">
        <div v-for="item in record.fms_capital_claim_logs" :key="item.id">
          <span>认领金额：{{ item.claim_amount }}认领人：{{ item.claim_ant_name }}</span>
        </div>
      </div>
    </s-table>
    <a-modal
      :visible="printInvoiceNoticeBillModel"
      :confirmLoading="printInvoiceNoticeBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印开票通知单"
      @cancel="printInvoiceNoticeBillModel = false"
    >
      <div id="printMmsInvoiceNoticeBill">
        <MmsInvoiceNoticeBill
          :currentData="currentData"
          :balanceData="detailData"
        ></MmsInvoiceNoticeBill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printMmsInvoiceNoticeBillBtn"
                  v-print="printMmsInvoiceNoticeBill">确认打印</a-button>
        <a-button type="primary" @click="printInvoiceNoticeBillModel = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { filterOption } from '@/utils/util'
import moment from 'moment';
import itmsBill from '@/views/fms/bill/FmsItmsBill';
import { getMmsSheetBill, getEInvoiceAndSendEmail } from '@/api/fms'
import { getMmsBalancePage } from '@/api/mms'
import MmsInvoiceNoticeBill from '@/views/fms/bill/MmsInvoiceNoticeBill';
import debounce from 'lodash/debounce'
import { getCustomer } from '@/api/common'

export default {
  name: 'WmsSaleInvoiceBill',
  components: {
    STable,
    itmsBill,
    MmsInvoiceNoticeBill
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      printInvoiceNoticeBillModel: false,
      printInvoiceNoticeBillModelLoading: false,
      printMmsInvoiceNoticeBill: {
        id: 'printMmsInvoiceNoticeBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      detailData: [],
      currentData: {},
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '开票单号',
          dataIndex: 'balance_num'
        },
        {
          title: '订单编号',
          dataIndex: 'order_num_set',
          scopedSlots: { customRender: 'orderNumSet' }
        },
        {
          title: '开票状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: '接单日期',
          dataIndex: 'order_date_set',
          customRender: (text) => {
            if (typeof text !== 'undefined') {
              const names = text.map(i => {
                return moment(i).format('YYYY/MM/DD')
              })
              return names.join('\n')
            } else {
              return ''
            }
          }
        },
        {
          title: '委托客户',
          dataIndex: 'customer_name'
        },
        {
          title: '税率',
          dataIndex: 'tax_rate_value'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '开票信息',
          dataIndex: 'fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '发票信息',
          dataIndex: 'invoices',
          scopedSlots: { customRender: 'invoiceItem' }
        },
        {
          title: '资金认领信息',
          dataIndex: 'fms_capital_claim_logs',
          scopedSlots: { customRender: 'fmsLogsItem' }
        },
        {
          title: '创建人',
          dataIndex: 'create_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: `right`,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getMmsBalancePage('getMmsBalancePage', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      feeStatusList: [
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 5, name: `待开票` },
        { value: 6, name: `已开票` }
      ],
      buyerOps: [],
      customerInfo: undefined,
      fetching: false
    }
  },
  methods: {
    filterOption,
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    // 打印开票单
    printInvoiceSheet(record) {
      if (record.audit_id) {
        this.currentData = record
        getMmsSheetBill({
          auditRecordId: record.audit_id
        }).then(v => {
          this.detailData = v
          this.currentData.apply_remark = record.remark
          this.currentData.customer = this.currentData.customer_name
          this.printInvoiceNoticeBillModel = true
        })
      } else {
        this.$message.error('暂无记录')
      }
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.buyerOps = [];
      this.fetching = true;
      getCustomer({
        department: 3,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.buyerOps = res;
        this.fetching = false;
      });
    },
    changeCustomerInfo(value) {
      this.customerInfo = value;
      this.queryParam['customer_id'] = value.key;
      this.buyerOps = [];
      this.fetching = false;
    },
    downloadInvoiceFile(item) {
      getEInvoiceAndSendEmail({ 'invoice_no': item.invoice_no, 'is_business': '1' }).then(v => {
        item.is_download = '1';
        if (v) {
          window.open(v, true);
        }
      })
    }
  },
  created() {}
}
</script>

<style scoped>

.balanceTitle{
  font-size: 20px;
  font-weight: 600;
}

</style>
